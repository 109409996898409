import { storeToRefs } from 'pinia';
import { productFruits } from 'product-fruits';
import { watch } from 'vue';

import { useFeatureToggle } from '../../../../config/composables/useFeatureToggle';

import { config } from '@/config/configuration';
import { useUserStore } from '@/stores/user.store';

export const useProductFruits = () => {
  const userStore = useUserStore();
  const { authenticatedUser } = storeToRefs(userStore);

  watch(authenticatedUser, (value, prev) => {
    if (prev === null && value) {
      const isProductFruitsEnabled = useFeatureToggle('productFruits');
      if (!isProductFruitsEnabled.value || navigator.webdriver) {
        console.info('Product Fruits not enabled. Skipping ...');
        return;
      }
      if (!config.productFruitsWorkspace) {
        console.error('Product Fruits workspace id is not supplied. Skipping ...');
        return;
      }
      productFruits.init(config.productFruitsWorkspace, value.appScope?.lang ?? 'en', {
        email: value.email ?? '',
        username: value.email ?? '',
      });
    }
  });
};

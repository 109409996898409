import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "20",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M.5 10c0-5.522 4.478-10 10-10s10 4.478 10 10-4.477 10-10 10-10-4.477-10-10m10-5a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5m0 3.75a.75.75 0 0 1 .75.75v4.75a.75.75 0 0 1-1.5 0V9.5a.75.75 0 0 1 .75-.75",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }
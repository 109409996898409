type AvailableDetailType = 'sales-amount' | 'sales-quantity' | 'market-share';

export const routes = {
  login: '/login',
  performance: '/',
  detail: (name: AvailableDetailType | ':name' = ':name') => `/detail/${name}`,
  businessEquation: '/business-equation',
  admin: {
    home: '/admin',
    account: '/admin/account',
  },
  support: '/support',
  tools: '/tools',
  settings: {
    home: '/settings',
    edit: '/settings/edit',
  },
  onboarding: '/onboarding',
  error: '/error',
};

import { z } from 'zod';

const filtersEntitySchema = z.object({
  eans: z.array(z.string()).optional(),
  stores: z.array(z.string()).optional(),
  activityTypes: z.array(z.string()).optional(),
  formats: z.array(z.string()).optional(),
  products: z.array(z.string()).optional(),
  brands: z.array(z.string()).optional(),
});
type FiltersEntityType = z.infer<typeof filtersEntitySchema>;

export { filtersEntitySchema, type FiltersEntityType };

/* *** PERIOD *** */
export interface PeriodIntervalType {
  start: string;
  end: string;
}

export const NIELSEN_LOOKER_PERIOD = 'period_code';
export const NielsenLookerPeriodType = typeof NIELSEN_LOOKER_PERIOD;

export const DATE_LOOKER_PERIODS = [
  'date_key_date',
  'date_key_week',
  'date_key_month',
  'date_filter',
  /** @deprecated use date_filter. To be deleted when likeForLike view allow date_filter usage */
  'date_key',
] as const;
export type DateLookerPeriodsType = typeof DATE_LOOKER_PERIODS;
export type DateLookerPeriodType = (typeof DATE_LOOKER_PERIODS)[number];

export const LOOKER_PERIODS = [...DATE_LOOKER_PERIODS, NIELSEN_LOOKER_PERIOD] as const;
export type LookerPeriodsType = typeof LOOKER_PERIODS;
export type LookerPeriodType = (typeof LOOKER_PERIODS)[number];

export const PERIODS_DEFAULT = ['weekly', 'monthly'] as const;
export type PeriodsDefault = typeof PERIODS_DEFAULT;
export type PeriodDefault = (typeof PERIODS_DEFAULT)[number];

export const PERIODS = ['daily', 'weekly', 'monthly', 'nielsen'] as const;
export type Periods = typeof PERIODS;
export type Period = (typeof PERIODS)[number];

export function isPeriod(value: unknown): value is Period {
  return typeof value === 'string' && PERIODS.includes(value as Period);
}

export const PERIOD_TO_LOOKER_PERIOD: Record<Period, LookerPeriodType> = {
  daily: 'date_key_date',
  weekly: 'date_key_week',
  monthly: 'date_key_month',
  nielsen: 'period_code',
} as const;

export const DATE_SELECTION_OPTIONS = [
  'dateRange',
  'year_to_date',
  'cumulative_to_date',
  'last_period',
] as const;
export type DateSelectionOption = (typeof DATE_SELECTION_OPTIONS)[number];

export function isDateSelectionOption(value: unknown): value is DateSelectionOption {
  return typeof value === 'string' && DATE_SELECTION_OPTIONS.includes(value as DateSelectionOption);
}

export interface PeriodType {
  period: Period;
  option: DateSelectionOption;
}
export interface FiltersStoreStateType {
  selectedPeriod: PeriodType;
  referentialInterval: PeriodIntervalType;
}

/* *** TAXONOMY *** */
export const PRODUCT_TAXONOMIES = ['carrefour', 'nielsen'] as const;
export type ProductTaxonomyType = (typeof PRODUCT_TAXONOMIES)[number];
export const isProductTaxonomy = (value: string): value is ProductTaxonomyType =>
  PRODUCT_TAXONOMIES.includes(value as ProductTaxonomyType);

/* *** PERIMETERS *** */
export const STORE_PERIMETERS = ['allStores', 'likeForLike'] as const;
export type StorePerimeterType = (typeof STORE_PERIMETERS)[number];
export const isStorePerimeterType = (value: string): value is StorePerimeterType =>
  STORE_PERIMETERS.includes(value as StorePerimeterType);

/* *** FILTERS *** */

export const SIMPLE_FILTERS = ['integratedFranchised', 'activityType', 'chainType'] as const;
export type SimpleFilter = (typeof SIMPLE_FILTERS)[number];

export const KPI_SCOPE_FILTERS = [
  'activity_type',
  'main_holding_key', // Fournisseur
  'supplier_key', // Sub supplier
  'supplier_root_key', // Local supplier
  'brand_key',
  'brand_type_key',
  'store_chain_type_desc',
  'store_chain_type_key',
  'sector_key',
  'department_key',
  'store_key',
  'store_region_desc',
  'store_region_key',
  'store_int_flag',
  'grp_class_key',
  'class_key',
  'sub_class_key',
  'item_key',
  'barcode',
  'isWithTax',
] as const;
export type KpiScopeFilterType = (typeof KPI_SCOPE_FILTERS)[number];

export const PRODUCT_REFERENTIAL_FILTERS = [
  'brandType',
  'class',
  'department',
  'grpClass',
  'item',
  'mainItem',
  'sector',
  'subClass',
  'barcode',
] as const;

export type ProductReferentialFilterType = (typeof PRODUCT_REFERENTIAL_FILTERS)[number];

export function isProductReferentialFilterType(
  value: string,
): value is ProductReferentialFilterType {
  return PRODUCT_REFERENTIAL_FILTERS.includes(value as ProductReferentialFilterType);
}

export const STORE_REFERENTIAL_FILTERS = [
  'bemMainHolding', // this is more a product referential filter
  'subSupplier', // this is more a product referential filter
  'localSupplier', // this is more a product referential filter
  'brand', // this is more a product referential filter

  /** @deprecated Use subSupplier instead. Remove at this end of revamp filter */
  'supplier',

  'activityType',
  'chainType',
  'store',
  'brand',
  'region',
  'integratedFranchised',
] as const;
export type StoreReferentialFilterType = (typeof STORE_REFERENTIAL_FILTERS)[number];
export function isStoreReferentialFilterType(value: string): value is StoreReferentialFilterType {
  return STORE_REFERENTIAL_FILTERS.includes(value as StoreReferentialFilterType);
}

export const ALL_FILTER_TYPES = [
  ...PRODUCT_REFERENTIAL_FILTERS,
  ...STORE_REFERENTIAL_FILTERS,
] as const;
export type AllFilterType = (typeof ALL_FILTER_TYPES)[number];
export function isFilterTypeGuard(filter: string): filter is AllFilterType {
  return ALL_FILTER_TYPES.includes(filter as AllFilterType);
}

export const FILTER_NAME_TO_KPI_FILTER: Record<AllFilterType, KpiScopeFilterType> = {
  activityType: 'activity_type',
  bemMainHolding: 'main_holding_key',
  /** @deprecated Use subSupplier instead. Remove at this end of revamp filter */
  supplier: 'supplier_key',
  subSupplier: 'supplier_key',
  localSupplier: 'supplier_root_key',
  brand: 'brand_key',
  brandType: 'brand_type_key',
  chainType: 'store_chain_type_key',
  region: 'store_region_key',
  class: 'class_key',
  department: 'department_key',
  grpClass: 'grp_class_key',
  item: 'item_key',
  mainItem: 'barcode',
  sector: 'sector_key',
  integratedFranchised: 'store_int_flag',
  store: 'store_key',
  subClass: 'sub_class_key',
  barcode: 'barcode',
};

import type { UnitType } from './scalar';
import type { KpiGranularity } from './permissions.model';
import type { ProductTaxonomyType } from './kpis-perimeter.model';
import type { DataTableKpiDisplayNameType, KpiDisplayNameType } from './kpi.model';
import { DIMENSIONS_DATATABLE } from './dimensions.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableRow = Record<string, any>;

/**
 * Defines the data structure of a single column in the data table. It must correspond
 * to one of the properties of the items passed to the data table. Namely, the `columnName`
 * property must be one of the keys of the items passed to the data table.
 *
 * @template T The type of the items passed to the data table.
 * @returns An object that defines the data structure of a single column in the data table.
 * @example
 * DataTableColumn<SomeType> = {
 *  name: 'someProperty',
 *  label: 'Some Property',
 *  href: '/some/path',
 *  isSortable: true,
 *  sort: ['asc', 'desc'],
 *  cellClass: 'col-6',
 * }
 */
export type DataTableColumn<T extends DataTableRow = DataTableRow> = {
  type: 'dimension' | 'kpi';
  name: keyof T;
  label: string;
  isSortable?: boolean;
  sort?: string[];
  href?: string;
  cellClass?: string;
};

export type DataTableCellType =
  | {
      value: number | null;
      valueUnit: UnitType;
      valuePlusSign?: boolean;
      performance?: number | null;
      performanceUnit?: UnitType;
      benchmark?: number | null;
      benchmarkUnit?: UnitType;
    }
  | {
      text: string[];
    };

interface RequirementsForKpi {
  granularity: KpiGranularity;
  taxonomy: Array<ProductTaxonomyType>;
}

export const DATA_TABLE_KPI_COLUMN_NAMES_TO_REQUIREMENTS: Record<
  DataTableKpiDisplayNameType,
  RequirementsForKpi
> = {
  sales_amount: {
    granularity: 'sales',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_amount_without_promo: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_amount_with_promo: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_amount_promo_percent: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_quantity: {
    granularity: 'quantity',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_quantity_without_promo: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_quantity_with_promo: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_quantity_promo_percent: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  average_weekly_sales_amount: {
    granularity: 'averageWeeklySales',
    taxonomy: ['carrefour', 'nielsen'],
  },
  average_weekly_sales_quantity: {
    granularity: 'averageWeeklySales',
    taxonomy: ['carrefour', 'nielsen'],
  },
  average_price: {
    granularity: 'averagePrice',
    taxonomy: ['carrefour', 'nielsen'],
  },
  average_price_with_promo: {
    granularity: 'promotion',
    taxonomy: ['carrefour', 'nielsen'],
  },
  sales_possession_rate: {
    granularity: 'salesPossessionRate',
    taxonomy: ['carrefour'],
  },
  gross_service_rate_downstream: {
    granularity: 'grossServiceRate',
    taxonomy: ['carrefour'],
  },
  gross_service_rate_upstream: {
    granularity: 'grossServiceRate',
    taxonomy: ['carrefour'],
  },
  net_service_rate_downstream: {
    granularity: 'netServiceRate',
    taxonomy: ['carrefour'],
  },
  net_service_rate_upstream: {
    granularity: 'netServiceRate',
    taxonomy: ['carrefour'],
  },
  supplier_market_share_sales_amount: {
    granularity: 'carrefourMarketShare',
    taxonomy: ['carrefour'],
  },
  supplier_market_share_sales_quantity: {
    granularity: 'carrefourMarketShare',
    taxonomy: ['carrefour'],
  },
  nielsen_market_share_sales_amount: {
    granularity: 'nielsenMarketShare',
    taxonomy: ['nielsen'],
  },
  nielsen_market_share_sales_quantity: {
    granularity: 'nielsenMarketShare',
    taxonomy: ['nielsen'],
  },
  supplier_market_share_sales_amount_comparison: {
    granularity: 'carrefourMarketShare',
    taxonomy: ['carrefour'],
  },
  supplier_market_share_sales_quantity_comparison: {
    granularity: 'carrefourMarketShare',
    taxonomy: ['carrefour'],
  },
  nielsen_market_share_sales_amount_comparison: {
    granularity: 'nielsenMarketShare',
    taxonomy: ['nielsen'],
  },
  nielsen_market_share_sales_quantity_comparison: {
    granularity: 'nielsenMarketShare',
    taxonomy: ['nielsen'],
  },
  warehouse_stock_value: {
    granularity: 'warehouseStock',
    taxonomy: ['carrefour'],
  },
  warehouse_stock_quantity: {
    granularity: 'warehouseStock',
    taxonomy: ['carrefour'],
  },
  store_stock_value: {
    granularity: 'storeStock',
    taxonomy: ['carrefour'],
  },
  store_stock_quantity: {
    granularity: 'storeStock',
    taxonomy: ['carrefour'],
  },
  total_stock_value: {
    granularity: 'totalStock',
    taxonomy: ['carrefour'],
  },
  total_stock_quantity: {
    granularity: 'totalStock',
    taxonomy: ['carrefour'],
  },
  number_of_transactions: {
    granularity: 'businessEquationAllCustomers',
    taxonomy: ['carrefour'],
  },
  average_basket_amount: {
    granularity: 'businessEquationAllCustomers',
    taxonomy: ['carrefour'],
  },
  average_basket_amount_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  average_basket_quantity: {
    granularity: 'businessEquationAllCustomers',
    taxonomy: ['carrefour'],
  },
  carrefour_market_sales: {
    granularity: 'businessEquationAllCustomers',
    taxonomy: ['carrefour'],
  },
  number_of_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  sales_amount_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  sales_amount_non_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  number_of_transactions_non_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  average_basket_amount_non_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  average_basket_quantity_non_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  average_price_non_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  frequency_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
  sales_by_customer_cardholders: {
    granularity: 'businessEquationCardholdersNonCardholders',
    taxonomy: ['carrefour'],
  },
};

export const DATA_TABLE_VIEWS = [
  'performance',
  'businessEquationAllCustomersDetail',
  'businessEquationCardholdersNonCardholdersDetail',
  'salesAmountDetail',
  'salesQuantityDetail',
] as const;
export type DataTableViewType = (typeof DATA_TABLE_VIEWS)[number];
export const DATA_TABLE_KPIS_PER_VIEW: Record<
  DataTableViewType,
  Array<DataTableKpiDisplayNameType>
> = {
  performance: [
    'sales_amount',
    'sales_amount_without_promo',
    'sales_amount_with_promo',
    'sales_amount_promo_percent',
    'sales_quantity',
    'sales_quantity_without_promo',
    'sales_quantity_promo_percent',
    'average_weekly_sales_amount',
    'average_weekly_sales_quantity',
    'average_price',
    'average_price_with_promo',
    'supplier_market_share_sales_amount',
    'supplier_market_share_sales_amount_comparison',
    'supplier_market_share_sales_quantity',
    'supplier_market_share_sales_quantity_comparison',
    'nielsen_market_share_sales_amount',
    'nielsen_market_share_sales_amount_comparison',
    'nielsen_market_share_sales_quantity',
    'nielsen_market_share_sales_quantity_comparison',
    'sales_possession_rate',
    'gross_service_rate_upstream',
    'gross_service_rate_downstream',
    'net_service_rate_upstream',
    'net_service_rate_downstream',
    'warehouse_stock_value',
    'warehouse_stock_quantity',
    'store_stock_value',
    'store_stock_quantity',
    'total_stock_value',
    'total_stock_quantity',
    'number_of_transactions',
    'average_basket_amount',
    'average_basket_amount_cardholders',
    'number_of_cardholders',
  ],
  businessEquationAllCustomersDetail: [
    'supplier_market_share_sales_amount',
    'supplier_market_share_sales_amount_comparison',
    'carrefour_market_sales',
    'sales_quantity',
    'sales_amount',
    'number_of_transactions',
    'average_basket_amount',
    'average_basket_quantity',
    'average_price',
  ],
  businessEquationCardholdersNonCardholdersDetail: [
    'supplier_market_share_sales_amount',
    'supplier_market_share_sales_amount_comparison',
    'carrefour_market_sales',
    'sales_quantity',
    'sales_amount',
    'sales_amount_non_cardholders',
    'number_of_transactions_non_cardholders',
    'average_basket_amount_non_cardholders',
    'average_basket_quantity_non_cardholders',
    'average_price_non_cardholders',
    'sales_amount_cardholders',
    'number_of_cardholders',
    'sales_by_customer_cardholders',
    'frequency_cardholders',
    'average_basket_amount_cardholders',
  ],
  salesAmountDetail: [
    'sales_amount',
    'sales_amount_without_promo',
    'sales_amount_with_promo',
    'sales_amount_promo_percent',
  ],
  salesQuantityDetail: [
    'sales_quantity',
    'sales_quantity_without_promo',
    'sales_quantity_with_promo',
    'sales_quantity_promo_percent',
  ],
};

export const DATA_TABLE_EXPORT_KPIS_FAMILIES = [
  'sales_amount',
  'sales_quantity',
  'average_weekly_sales_amount',
  'average_weekly_sales_quantity',
  'average_price',
  'supplier_market_share_sales_amount',
  'supplier_market_share_sales_quantity',
  'nielsen_market_share_sales_amount',
  'nielsen_market_share_sales_quantity',
  'sales_possession_rate',
  'gross_service_rate',
  'net_service_rate',
  'warehouse_stock',
  'store_stock',
  'total_stock',
  'number_of_transactions',
  'average_basket_amount',
  'average_basket_amount_cardholders',
  'average_basket_quantity',
  'carrefour_market_sales',
  'number_of_cardholders',
] as const;
export type DataTableExportKpiFamilyType = (typeof DATA_TABLE_EXPORT_KPIS_FAMILIES)[number];

export const DATA_TABLE_EXPORT_DIMENSIONS = [
  ...DIMENSIONS_DATATABLE,
  'top_flop_ca',
  'top_flop_uvc',
] as const;
export type ExportableDimensionType = (typeof DATA_TABLE_EXPORT_DIMENSIONS)[number];

export function containsTopFlopDimension(dimensions: ExportableDimensionType[]) {
  return dimensions.includes('top_flop_ca') || dimensions.includes('top_flop_uvc');
}

export const DATA_TABLE_EXPORT_KPIS_FAMILIES_TO_MEASURES: Record<
  DataTableViewType,
  | {
      [key in DataTableExportKpiFamilyType]?: KpiDisplayNameType[];
    }
  | null
> = {
  performance: {
    sales_amount: [
      'sales_amount',
      'sales_amount_comparison',
      'sales_amount_benchmark',
      'sales_amount_with_promo',
      'sales_amount_with_promo_comparison',
      'sales_amount_with_promo_benchmark',
      'sales_amount_without_promo',
      'sales_amount_without_promo_comparison',
      'sales_amount_without_promo_benchmark',
      'sales_amount_promo_percent',
      'sales_amount_promo_percent_comparison',
      'sales_amount_promo_percent_benchmark',
    ],
    sales_quantity: [
      'sales_quantity',
      'sales_quantity_comparison',
      'sales_quantity_benchmark',
      'sales_quantity_with_promo',
      'sales_quantity_with_promo_comparison',
      'sales_quantity_with_promo_benchmark',
      'sales_quantity_without_promo',
      'sales_quantity_without_promo_comparison',
      'sales_quantity_without_promo_benchmark',
      'sales_quantity_promo_percent',
      'sales_quantity_promo_percent_comparison',
      'sales_quantity_promo_percent_benchmark',
    ],
    average_weekly_sales_amount: [
      'average_weekly_sales_amount',
      'average_weekly_sales_amount_comparison',
      'average_weekly_sales_amount_benchmark',
    ],
    average_weekly_sales_quantity: [
      'average_weekly_sales_quantity',
      'average_weekly_sales_quantity_comparison',
      'average_weekly_sales_quantity_benchmark',
    ],
    average_price: [
      'average_price',
      'average_price_comparison',
      'average_price_benchmark',
      'average_price_with_promo',
      'average_price_with_promo_comparison',
      'average_price_with_promo_benchmark',
    ],
    supplier_market_share_sales_amount: [
      'supplier_market_share_sales_amount',
      'supplier_market_share_sales_amount_comparison',
    ],
    supplier_market_share_sales_quantity: [
      'supplier_market_share_sales_quantity',
      'supplier_market_share_sales_quantity_comparison',
    ],
    nielsen_market_share_sales_amount: [
      'nielsen_market_share_sales_amount',
      'nielsen_market_share_sales_amount_comparison',
    ],
    nielsen_market_share_sales_quantity: [
      'nielsen_market_share_sales_quantity',
      'nielsen_market_share_sales_quantity_comparison',
    ],
    sales_possession_rate: ['sales_possession_rate', 'sales_possession_rate_comparison'],
    gross_service_rate: [
      'gross_service_rate_downstream',
      'gross_service_rate_downstream_comparison',
      'gross_service_rate_upstream',
      'gross_service_rate_upstream_comparison',
    ],
    net_service_rate: [
      'net_service_rate_downstream',
      'net_service_rate_downstream_comparison',
      'net_service_rate_upstream',
      'net_service_rate_upstream_comparison',
    ],
    warehouse_stock: [
      'warehouse_stock_value',
      'warehouse_stock_value_comparison',
      'warehouse_stock_quantity',
      'warehouse_stock_quantity_comparison',
    ],
    store_stock: [
      'store_stock_value',
      'store_stock_value_comparison',
      'store_stock_quantity',
      'store_stock_quantity_comparison',
    ],
    total_stock: [
      'total_stock_value',
      'total_stock_value_comparison',
      'total_stock_quantity',
      'total_stock_quantity_comparison',
    ],
    number_of_transactions: [
      'number_of_transactions',
      'number_of_transactions_comparison',
      'number_of_transactions_benchmark',
    ],
    average_basket_amount: [
      'average_basket_amount',
      'average_basket_amount_comparison',
      'average_basket_amount_benchmark',
    ],
    average_basket_amount_cardholders: [
      'average_basket_amount_cardholders',
      'average_basket_amount_cardholders_comparison',
      'average_basket_amount_cardholders_benchmark',
    ],
    number_of_cardholders: [
      'number_of_cardholders',
      'number_of_cardholders_comparison',
      'number_of_cardholders_benchmark',
    ],
  },
  businessEquationAllCustomersDetail: {
    supplier_market_share_sales_amount: [
      'supplier_market_share_sales_amount',
      'supplier_market_share_sales_amount_comparison',
    ],
    carrefour_market_sales: ['carrefour_market_sales', 'carrefour_market_sales_comparison'],
    sales_quantity: ['sales_quantity', 'sales_quantity_comparison', 'sales_quantity_benchmark'],
    sales_amount: ['sales_amount', 'sales_amount_comparison', 'sales_amount_benchmark'],
    number_of_transactions: [
      'number_of_transactions',
      'number_of_transactions_comparison',
      'number_of_transactions_benchmark',
    ],
    average_basket_amount: [
      'average_basket_amount',
      'average_basket_amount_comparison',
      'average_basket_amount_benchmark',
    ],
    average_basket_quantity: [
      'average_basket_quantity',
      'average_basket_quantity_comparison',
      'average_basket_quantity_benchmark',
    ],
    average_price: ['average_price', 'average_price_comparison', 'average_price_benchmark'],
  },
  businessEquationCardholdersNonCardholdersDetail: {},
  salesAmountDetail: null,
  salesQuantityDetail: null,
};

/**
 * Custom dimension to export
 * top_flop_ca => "sales_amount" KPI with dimension "barcode"
 * top_flop_uvc => "sales_quantity" KPI with dimension "barcode"
 */
export const TOP_FLOP_DIMENSIONS = ['top_flop_ca', 'top_flop_uvc'] as const;

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { LocationQueryRaw } from 'vue-router';

import { FILTERS_STATE_DEFAULT_VALUES } from '../defaultPerimeter.const';
import type { FilterNameType, KpisPerimeterType } from '../kpisPerimeter.model';
import type { FiltersStateType } from '../kpisPerimeter.model';
import { useUrlQueryString, type QueryRouter } from '../useUrlQueryString';

import { useGetFiltersForKpis } from './useGetFiltersForKpis';
import { useGetFiltersFromQuery } from './useGetFiltersFromQuery';
import { useGetFiltersTree } from './useGetFiltersTree';

import { useFeatureToggle } from '@/config/composables/useFeatureToggle';
import { useUserStore } from '@/stores/user.store';

export const countFilters = (filters: FiltersStateType) => {
  return Object.values(filters).reduce((total, filterValues) => {
    total += filterValues.length;
    return total;
  }, 0);
};

export const useFilters = (
  router: QueryRouter,
): KpisPerimeterType['filters'] & {
  generateSelectedFiltersQueryParams: (filters: FiltersStateType) => LocationQueryRaw;
} => {
  const userStore = useUserStore();
  const { defaultKpisPerimeter } = storeToRefs(userStore);

  const { updateQuery } = useUrlQueryString(router);

  const currentFiltersValue = useGetSelectedFilters(router);
  const selectedFiltersKpiQuery = useGetFiltersForKpis(router);
  const selectedFiltersTree = useGetFiltersTree(router);

  const selectedFiltersCount = computed(() => countFilters(currentFiltersValue.value));

  const isDefault = (
    currentValues: FiltersStateType = currentFiltersValue.value,
    keysToCheck?: FilterNameType[],
  ) => {
    const isEqual = (current: string[], actual: string[]) => {
      const sortedCurrentValues = current.sort();
      const sortedDefaultValues = actual.sort();

      return JSON.stringify(sortedCurrentValues) === JSON.stringify(sortedDefaultValues);
    };

    if (keysToCheck?.length) {
      return keysToCheck.every((key) => {
        return isEqual(currentValues[key], defaultKpisPerimeter.value.filters[key]);
      });
    }

    return Object.entries(currentValues).every(([key, value]) => {
      return isEqual(value, defaultKpisPerimeter.value.filters[key as FilterNameType]);
    });
  };

  const generateSelectedFiltersQueryParams = (filters: FiltersStateType) => {
    if (isDefault(filters)) {
      return {
        nomenclatureFilters: undefined,
        activityTypesFilters: undefined,
        storesFilters: undefined,
        integratedFranchisedFilters: undefined,
        tryptiqueFilters: undefined,
        suppliersFilters: undefined,
        brandsFilters: undefined,
        bemHoldingFilters: undefined,
        itemFilters: undefined,
        barcodeFilters: undefined,
        storesRegionFilters: undefined,
      };
    }

    const undefinedIfDefault = (filterType: FilterNameType, filterValues: string[]) => {
      if (
        isDefault({ ...FILTERS_STATE_DEFAULT_VALUES, [filterType]: filterValues }, [filterType])
      ) {
        return undefined;
      }

      if (filterValues.length === 0) {
        // As empty array display nothing in the url, we need to be able
        // to distinguish nothing is selected from default value.
        // Using null as value will display query string key only, allowing to
        // set correct filter values when reloading the page.
        return null;
      }

      return filterValues;
    };

    return {
      nomenclatureFilters: undefinedIfDefault('nomenclatureFilters', filters.nomenclatureFilters),
      activityTypesFilters: undefinedIfDefault(
        'activityTypesFilters',
        filters.activityTypesFilters,
      ),
      storesFilters: undefinedIfDefault('storesFilters', filters.storesFilters),
      integratedFranchisedFilters: undefinedIfDefault(
        'integratedFranchisedFilters',
        filters.integratedFranchisedFilters,
      ),
      tryptiqueFilters: undefinedIfDefault('tryptiqueFilters', filters.tryptiqueFilters),
      brandsFilters: undefinedIfDefault('brandsFilters', filters.brandsFilters),
      suppliersFilters: undefinedIfDefault('suppliersFilters', filters.suppliersFilters),
      bemHoldingFilters: undefinedIfDefault('bemHoldingFilters', filters.bemHoldingFilters),
      itemFilters: undefinedIfDefault('itemFilters', filters.itemFilters),
      barcodeFilters: undefinedIfDefault('barcodeFilters', filters.barcodeFilters),
      storesRegionFilters: undefinedIfDefault('storesRegionFilters', filters.storesRegionFilters),
    };
  };

  const updateSelectedFilters = async (filters: FiltersStateType) => {
    await updateQuery(generateSelectedFiltersQueryParams(filters));
  };

  return {
    selectedFiltersKpiQuery,
    selectedFiltersTree,
    selectedFiltersCount,
    updateSelectedFilters,
    isDefault: () => isDefault(),
    generateSelectedFiltersQueryParams,
  };
};

const useGetSelectedFilters = (router: QueryRouter) => {
  const filters = useGetFiltersFromQuery(router);

  // TODO cleanup when all feature is done
  const isUsingFilterRevamp = useFeatureToggle('filtersRevamp');

  return computed<FiltersStateType>(() => ({
    nomenclatureFilters: [
      ...filters.sectorFilters.value,
      ...filters.departmentFilters.value,
      ...filters.groupClassFilters.value,
      ...filters.classFilters.value,
      ...filters.subClassFilters.value,
    ],
    activityTypesFilters: filters.activityTypeFilters.value,
    storesFilters: [...filters.storeFilters.value, ...filters.chainTypeFilters.value],
    integratedFranchisedFilters: filters.integratedFranchisedFilters.value,
    tryptiqueFilters: filters.triptyqueFilters.value,
    barcodeFilters: filters.barcodeFilters.value,
    itemFilters: filters.itemFilters.value,
    suppliersFilters: [...filters.subSupplierFilters.value, ...filters.localSupplierFilters.value],
    bemHoldingFilters: filters.bemHoldingFilters.value,
    brandsFilters: isUsingFilterRevamp.value
      ? filters.brandFilters.value
      : [
          ...filters.bemHoldingFilters.value,
          ...filters.supplierFilters.value,
          ...filters.brandFilters.value,
        ],
    storesRegionFilters: filters.storesRegionFilters.value,
  }));
};

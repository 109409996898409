import { type KpiGranularity } from '.';
import type { ProductTaxonomyType } from './kpis-perimeter.model';
import type { UserScope } from './userScope.model';

export const DIMENSIONS_DATATABLE = [
  'class_desc',
  'sub_class_desc',
  'main_holding_desc',
  'supplier_root_desc',
  'supplier_desc',
  'brand_type_desc',
  'brand_desc',
  'barcode',
  'store_chain_type_desc',
  'store_region_desc',
  'store_key',
] as const;
export type DataTableDimensionsType = (typeof DIMENSIONS_DATATABLE)[number];
export function isDataTableDimensionsType(value: string): value is DataTableDimensionsType {
  return DIMENSIONS_DATATABLE.includes(value as DataTableDimensionsType);
}

const NIELSEN_UNAVAILABLE_DIMENSIONS: DataTableDimensionsType[] = [
  'store_chain_type_desc',
  'store_region_desc',
  'store_key',
];

const DIMENSION_TO_GRANULARITY: Record<DataTableDimensionsType, KpiGranularity | undefined> = {
  class_desc: undefined,
  sub_class_desc: undefined,
  main_holding_desc: 'holding',
  supplier_root_desc: 'localSupplier',
  supplier_desc: 'subSupplier',
  brand_type_desc: 'brandType',
  brand_desc: 'brand',
  barcode: 'ean',
  store_chain_type_desc: 'chainType',
  store_region_desc: 'region',
  store_key: 'stores',
};

interface filterDimensionsByUserScopeAndReferentialArgs {
  dimensions: ReadonlyArray<DataTableDimensionsType>;
  userScope: UserScope;
  referential: ProductTaxonomyType;
}
export function filterDimensionsByUserScopeAndReferential({
  dimensions,
  referential,
  userScope,
}: filterDimensionsByUserScopeAndReferentialArgs): ReadonlyArray<DataTableDimensionsType> {
  return dimensions.filter((dimension) => {
    // Remove  dimensions not available in Nielsen referential
    if (referential === 'nielsen' && NIELSEN_UNAVAILABLE_DIMENSIONS.includes(dimension)) {
      return false;
    }

    const requiredGranularity = DIMENSION_TO_GRANULARITY[dimension];

    if (!requiredGranularity) {
      return true;
    }

    return userScope.permissions.kpis.includes(requiredGranularity);
  });
}

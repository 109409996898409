import { extractFilterKeyFromNodeId, type AllFilterType } from '@carrefour-gcs/shared';
import { computed } from 'vue';

import type { FiltersTreeType } from '../kpisPerimeter.model';
import type { QueryRouter } from '../useUrlQueryString';

import { useGetFiltersFromQuery } from './useGetFiltersFromQuery';

import type { TreeItemsStateType } from '@/components/treeView';
import { useFeatureToggle } from '@/config/composables/useFeatureToggle';

export const useGetFiltersTree = (router: QueryRouter) => {
  const filterToTreeItem = (filters: string[], filterType: AllFilterType) => {
    return filters.reduce((tree, nodeId) => {
      tree[nodeId] = {
        nodeId,
        filterType,
        label: extractFilterKeyFromNodeId(nodeId),
        state: 'checked',
      };

      return tree;
    }, {} as TreeItemsStateType);
  };

  // TODO cleanup when all feature is done
  const isUsingFilterRevamp = useFeatureToggle('filtersRevamp');

  return computed<FiltersTreeType>(() => {
    const filters = useGetFiltersFromQuery(router);

    return {
      nomenclatureFilters: {
        ...filterToTreeItem(filters.sectorFilters.value, 'sector'),
        ...filterToTreeItem(filters.classFilters.value, 'class'),
        ...filterToTreeItem(filters.groupClassFilters.value, 'grpClass'),
        ...filterToTreeItem(filters.departmentFilters.value, 'department'),
        ...filterToTreeItem(filters.subClassFilters.value, 'subClass'),
      },
      activityTypesFilters: filterToTreeItem(filters.activityTypeFilters.value, 'activityType'),
      barcodeFilters: filterToTreeItem(filters.barcodeFilters.value, 'barcode'),
      suppliersFilters: {
        ...filterToTreeItem(filters.subSupplierFilters.value, 'subSupplier'),
        ...filterToTreeItem(filters.localSupplierFilters.value, 'localSupplier'),
      },
      brandsFilters: isUsingFilterRevamp.value
        ? filterToTreeItem(filters.brandFilters.value, 'brand')
        : {
            ...filterToTreeItem(filters.bemHoldingFilters.value, 'bemMainHolding'),
            ...filterToTreeItem(filters.supplierFilters.value, 'supplier'),
            ...filterToTreeItem(filters.brandFilters.value, 'brand'),
          },
      bemHoldingFilters: filterToTreeItem(filters.bemHoldingFilters.value, 'bemMainHolding'),
      itemFilters: filterToTreeItem(filters.itemFilters.value, 'item'),
      integratedFranchisedFilters: filterToTreeItem(
        filters.integratedFranchisedFilters.value,
        'integratedFranchised',
      ),
      storesFilters: {
        ...filterToTreeItem(filters.storeFilters.value, 'store'),
        ...filterToTreeItem(filters.chainTypeFilters.value, 'chainType'),
      },
      tryptiqueFilters: filterToTreeItem(filters.triptyqueFilters.value, 'brandType'),
      storesRegionFilters: filterToTreeItem(filters.storesRegionFilters.value, 'region'),
    };
  });
};
